<template>
  <el-container>
    <el-main style="overflow: auto !important">
      <router-view></router-view>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: "changeSupplierStatus",
  mounted() {
    this.$router.push({
      name: "SupplierManagementList",
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path == "/SupplierManagement") {
      this.$router.push({
        name: "SupplierManagementList",
      });
      next();
    }
    next();
  },
};
</script>

<style lang="scss" scoped></style>
